var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "faq-page" } }, [
    _c("div", { staticClass: "faq-jumbotron" }, [
      _c(
        "div",
        {
          staticClass:
            "faq-jumbotron-content lg:p-32 md:p-24 sm:p-16 p-8 rounded-lg mb-base",
        },
        [
          _c("h1", { staticClass: "mb-1 text-white" }, [
            _vm._v("Have Any Questions?"),
          ]),
          _c("p", { staticClass: "text-white" }, [
            _vm._v(
              "Bonbon sesame snaps lemon drops marshmallow ice cream carrot cake croissant wafer."
            ),
          ]),
          _c("vs-input", {
            staticClass: "w-full mt-6",
            attrs: {
              placeholder: "Search",
              "icon-pack": "feather",
              icon: "icon-search",
              size: "large",
              "icon-no-border": "",
            },
            model: {
              value: _vm.faqSearchQuery,
              callback: function ($$v) {
                _vm.faqSearchQuery = $$v
              },
              expression: "faqSearchQuery",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-2/5 lg:w-1/4 rounded-lg" },
        [
          _c("vx-card", [
            _c("h4", [_vm._v("Table of Content")]),
            _c(
              "ul",
              { staticClass: "faq-topics mt-4" },
              _vm._l(_vm.categories, function (category) {
                return _c(
                  "li",
                  {
                    key: category.id,
                    staticClass: "p-2 font-medium flex items-center",
                    on: {
                      click: function ($event) {
                        _vm.faqFilter = category.id
                      },
                    },
                  },
                  [
                    _c("div", {
                      staticClass: "h-3 w-3 rounded-full mr-2",
                      class: "bg-" + category.color,
                    }),
                    _c("span", { staticClass: "cursor-pointer" }, [
                      _vm._v(_vm._s(category.name)),
                    ]),
                  ]
                )
              }),
              0
            ),
            _c("br"),
            _c("br"),
            _c("h4", [_vm._v("Supporters")]),
            _c(
              "ul",
              { staticClass: "faq-supporters mt-4" },
              _vm._l(_vm.supporters, function (supporter) {
                return _c("li", { key: supporter.id, staticClass: "mt-4" }, [
                  _c(
                    "div",
                    { staticClass: "flex items-center" },
                    [
                      _c("vs-avatar", {
                        staticClass: "mr-3",
                        attrs: { src: supporter.img, size: "35px" },
                      }),
                      _c("div", { staticClass: "leading-tight" }, [
                        _c("p", { staticClass: "font-semibold" }, [
                          _vm._v(_vm._s(supporter.name)),
                        ]),
                        _c("small", [_vm._v(_vm._s(supporter.profession))]),
                      ]),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-3/5 lg:w-3/4 mt-12 md:mt-0" },
        [
          _c(
            "vs-collapse",
            { staticClass: "p-0", attrs: { accordion: "", type: "margin" } },
            _vm._l(_vm.filteredFaq, function (que, index) {
              return _c(
                "vs-collapse-item",
                {
                  key: que.id,
                  staticClass: "faq-bg rounded-lg",
                  class: { "mt-0": !index },
                },
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("h5", [_vm._v(_vm._s(que.question))]),
                  ]),
                  _c("p", [_vm._v(_vm._s(que.ans))]),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }